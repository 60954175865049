var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon custom-container" },
    [
      _c("h3", [_vm._v("Iconfont 對照頁")]),
      _c("div", { staticClass: "row-wrap" }, [
        _c(
          "div",
          { staticClass: "table-box", staticStyle: { "margin-right": "30px" } },
          [
            _c(
              "table",
              [
                _vm._m(0),
                _vm._l(_vm.iconfont, function(i, idx) {
                  return _c("tr", { key: idx }, [
                    _c("td", [_vm._v(_vm._s(i.name))]),
                    _c("td", { domProps: { innerHTML: _vm._s(i.icon) } }),
                    _c("td", { staticClass: "iconfont" }, [
                      _vm._v(_vm._s(i.unicode))
                    ])
                  ])
                })
              ],
              2
            )
          ]
        ),
        _c("div", { staticClass: "table-box" }, [
          _c(
            "table",
            [
              _vm._m(1),
              _vm._l(_vm.star, function(s, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", [_vm._v(_vm._s(s.name))]),
                  _c("td", { domProps: { innerHTML: _vm._s(s.icon) } }),
                  _c("td", { staticClass: "iconfont" }, [
                    _vm._v(_vm._s(s.unicode))
                  ])
                ])
              })
            ],
            2
          )
        ])
      ]),
      _c(
        "v-btn",
        {
          attrs: { color: "primary" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.dialog = true
            }
          }
        },
        [_vm._v("Open Dialog")]
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("v-card", [
            _c("div", { staticClass: "popup__box" }, [
              _c("img", {
                staticClass: "popup__img",
                attrs: { src: require("@/assets/404.svg"), alt: "" }
              }),
              _c("div", { staticClass: "popup__title" }, [_vm._v("404 Error")]),
              _c("div", { staticClass: "popup__tsubitle" }, [
                _vm._v("哎呀...暫時找不到您訪問的星球！")
              ]),
              _c("div", { staticClass: "popup__btn" }, [_vm._v("返回")])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("名稱")]),
      _c("th", [_vm._v("符號")]),
      _c("th", [_vm._v("Unicode")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("名稱")]),
      _c("th", [_vm._v("符號")]),
      _c("th", [_vm._v("Unicode")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }