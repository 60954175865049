<template>
  <div class="icon custom-container">
    <h3>Iconfont 對照頁</h3>

    <div class="row-wrap">
      <div class="table-box" style="margin-right: 30px">
        <table>
          <tr>
            <th>名稱</th>
            <th>符號</th>
            <th>Unicode</th>
          </tr>
          <tr v-for="(i, idx) in iconfont" :key="idx">
            <td>{{ i.name }}</td>
            <td v-html="i.icon"></td>
            <td class="iconfont">{{ i.unicode }}</td>
          </tr>
        </table>
      </div>
      <div class="table-box">
        <table>
          <tr>
            <th>名稱</th>
            <th>符號</th>
            <th>Unicode</th>
          </tr>
          <tr v-for="(s, idx) in star" :key="idx">
            <td>{{ s.name }}</td>
            <td v-html="s.icon"></td>
            <td class="iconfont">{{ s.unicode }}</td>
          </tr>
        </table>
      </div>
    </div>
    <v-btn color="primary" @click.stop="dialog = true">Open Dialog</v-btn>
    <!-- <div class="popup__overlay"> -->
    <v-dialog v-model="dialog">
      <v-card>
        <div class="popup__box">
          <img class="popup__img" src="@/assets/404.svg" alt />
          <div class="popup__title">404 Error</div>
          <div class="popup__tsubitle">哎呀...暫時找不到您訪問的星球！</div>
          <div class="popup__btn">返回</div>
        </div>
      </v-card>
    </v-dialog>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      //"","","","","","","","","","",
      iconfont: [
        {
          name: "日",
          icon: '<i class="iconfont">&#xe62e;</i>',
          unicode: "",
        },
        {
          name: "月",
          icon: '<i class="iconfont">&#xe623;</i>',
          unicode: "",
        },
        {
          name: "水",
          icon: '<i class="iconfont">&#xe62c;</i>',
          unicode: "",
        },
        {
          name: "金",
          icon: '<i class="iconfont">&#xe62f;</i>',
          unicode: "",
        },
        {
          name: "火",
          icon: '<i class="iconfont">&#xe61c;</i>',
          unicode: "",
        },
        {
          name: "木",
          icon: '<i class="iconfont">&#xe61d;</i>',
          unicode: "",
        },
        {
          name: "土",
          icon: '<i class="iconfont">&#xe627;</i>',
          unicode: "",
        },
        {
          name: "天",
          icon: '<i class="iconfont">&#xe633;</i>',
          unicode: "",
        },
        {
          name: "海",
          icon: '<i class="iconfont">&#xe624;</i>',
          unicode: "",
        },
        {
          name: "冥",
          icon: '<i class="iconfont">&#xe62a;</i>',
          unicode: "",
        },
        //"","","","","","","","","","",
        {
          name: "升",
          icon: '<i class="iconfont">&#xe61e;</i>',
          unicode: "",
        },
        {
          name: "頂",
          icon: '<i class="iconfont">&#xe629;</i>',
          unicode: "",
        },
        {
          name: "凱",
          icon: '<i class="iconfont">&#xe61b;</i>',
          unicode: "",
        },
        {
          name: "穀",
          icon: '<i class="iconfont">&#xe620;</i>',
          unicode: "",
        },
        {
          name: "智",
          icon: '<i class="iconfont">&#xe625;</i>',
          unicode: "",
        },
        {
          name: "婚",
          icon: '<i class="iconfont">&#xe61f;</i>',
          unicode: "",
        },
        {
          name: "灶",
          icon: '<i class="iconfont">&#xe632;</i>',
          unicode: "",
        },
        {
          name: "北",
          icon: '<i class="iconfont">&#xe62d;</i>',
          unicode: "",
        },
        {
          name: "南",
          icon: '<i class="iconfont">&#xe631;</i>',
          unicode: "",
        },
        {
          name: "莉",
          icon: '<i class="iconfont">&#xe621;</i>',
          unicode: "",
        },
        //"","","","",""
        {
          name: "福",
          icon: '<i class="iconfont">&#xe628;</i>',
          unicode: "",
        },
        {
          name: "宿",
          icon: '<i class="iconfont">&#xe630;</i>',
          unicode: "",
        },
        {
          name: "東",
          icon: '<i class="iconfont">&#xe622;</i>',
          unicode: "",
        },
        {
          name: "降",
          icon: '<i class="iconfont">&#xe62b;</i>',
          unicode: "",
        },
        {
          name: "底",
          icon: '<i class="iconfont">&#xe626;</i>',
          unicode: "",
        },
      ],
      //"","","","","","","","","","","",""
      star: [
        {
          name: "牡羊座 Aries",
          icon: '<i class="iconfont">&#xe636;</i>',
          unicode: "",
        },
        {
          name: "金牛座 Taurus",
          icon: '<i class="iconfont">&#xe637;</i>',
          unicode: "",
        },
        {
          name: "雙子座 Gemini",
          icon: '<i class="iconfont">&#xe640;</i>',
          unicode: "",
        },
        {
          name: "巨蟹座  Cancer",
          icon: '<i class="iconfont">&#xe63d;</i>',
          unicode: "",
        },
        {
          name: "獅子座 Leo",
          icon: '<i class="iconfont">&#xe63c;</i>',
          unicode: "",
        },
        {
          name: "處女座  Virgo",
          icon: '<i class="iconfont">&#xe63f;</i>',
          unicode: "",
        },
        {
          name: "天秤座 Libra",
          icon: '<i class="iconfont">&#xe635;</i>',
          unicode: "",
        },
        {
          name: "天蠍座 Scorpio",
          icon: '<i class="iconfont">&#xe63e;</i>',
          unicode: "",
        },
        {
          name: "射手座 Sagittarius",
          icon: '<i class="iconfont">&#xe634;</i>',
          unicode: "",
        },
        {
          name: "魔羯座 Capricorn",
          icon: '<i class="iconfont">&#xe63b;</i>',
          unicode: "",
        },
        {
          name: "水瓶座 Aquarius",
          icon: '<i class="iconfont">&#xe63a;</i>',
          unicode: "",
        },
        {
          name: "雙魚座 Pisces",
          icon: '<i class="iconfont">&#xe638;</i>',
          unicode: "",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
h3 {
  font-size: 30px;
  margin: 20px;
  text-align: center;
  font-weight: bold;
}
.row-wrap {
  @include align(center, flex-start);
}
.table-box {
  @include rect(100%, auto, $theme-color-3, 20px);
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 85vh;
  &::-webkit-scrollbar {
    width: 0px; //隱藏 scroll bar
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 0 8px;
  }
  th {
    height: 58px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.63;
    letter-spacing: 1.28px;
    color: $theme-color-1;
  }
  td {
    line-height: 54px;
    height: 54px;
    @include text-3;
  }
  tr {
    border-bottom: solid 0.4px rgba($color: $secondary-color-3, $alpha: 0.2);
    &.table__title {
      border-bottom: solid 0.4px $secondary-color-3;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.popup__overlay {
  @include align(center, center);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(240, 242, 244, 0.4);
  z-index: 1000;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  //   transition: 0.5s;
}

.popup__box {
  z-index: 1050;
  //   position: fixed;
  margin: auto;
  @include rect(628px, auto, $theme-color-3, 110px);
  @include center;
  flex-direction: column;
  padding: 32px 55px;
  transition: 0.5s;
  .popup__img {
    width: 397px;
  }
  .popup__title {
    font-family: "Yeseva One", cursive;
    font-size: 50px;
    text-align: center;
    margin-top: 68px;
    color: $secondary-color-1;
  }
  .popup__subtitle {
    font-size: 24px;
    text-align: center;
    margin-top: 16px;
    color: $secondary-color-3;
  }
  .popup__btn {
    @include btn(144px, 48px, $theme-color-1, $theme-color-3, 20px);
    margin-top: 48px;
    @include mobile() {
      @include btn(100, 40px, $theme-color-1, $theme-color-3, 14px);
    }
  }
  @include pad() {
    @include rect(90%, auto, $theme-color-3, 110px);
  }
  @include mobile() {
    padding: 40px 32px;
  }
}
</style>
